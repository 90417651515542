import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`26th February 2024`}</p>


    <h2 {...{
      "id": "-security-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-security-fixes",
        "aria-label": " security fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🔒 Security fixes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria's SAML integration now rejects unsigned SAML messages by default. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/security/advisories/GHSA-4m6j-23p2-8c54"
        }}>{`CVE-2024-1735`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You can set `}<a parentName="li" {...{
              "href": "type://SamlServiceProviderBuilder#signatureRequired(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/saml/SamlServiceProviderBuilder.html#signatureRequired(boolean)"
            }}>{`type://SamlServiceProviderBuilder#signatureRequired(boolean)`}</a>{` to control whether to accept unsigned SAML messages.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
        }}>{`type://XdsEndpointGroup`}</a>{` now supports `}<a parentName="li" {...{
          "href": "https://www.envoyproxy.io/docs/envoy/latest/intro/arch_overview/upstream/load_balancing/subsets"
        }}>{`Subset based Load Balancing`}</a>{`,
which enables more sophisticated load balancing configurations. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5461"
        }}>{`#5461`}</a></li>
      <li parentName="ul">{`You can now specify a static `}<inlineCode parentName="li">{`Cluster`}</inlineCode>{` configuration to define the `}<inlineCode parentName="li">{`ClusterLoadAssignment`}</inlineCode>{` instead of receiving
the `}<inlineCode parentName="li">{`Cluster`}</inlineCode>{` resource from the control plane. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5468"
        }}>{`#5468`}</a></li>
      <li parentName="ul">{`Armeria's `}<a parentName="li" {...{
          "href": "https://github.com/Netflix/eureka"
        }}>{`Eureka`}</a>{` integration no longer incorrectly appends
port information to `}<inlineCode parentName="li">{`vipAddress`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5451"
        }}>{`#5451`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`homePageUrl`}</inlineCode>{` and `}<inlineCode parentName="li">{`statusPageUrl`}</inlineCode>{` of an `}<inlineCode parentName="li">{`InstanceInfo`}</inlineCode>{` in Eureka are correctly set if corresponding path properties are configured. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5464"
        }}>{`#5464`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5465"
        }}>{`#5465`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`IllegalArgumentException`}</inlineCode>{` when using `}<a parentName="li" {...{
          "href": "type://ConnectionPoolListener#metricCollecting(MeterRegistry):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ConnectionPoolListener.html#metricCollecting(io.micrometer.core.instrument.MeterRegistry)?full"
        }}>{`type://ConnectionPoolListener#metricCollecting(MeterRegistry)?full`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5466"
        }}>{`#5466`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`.connections`}</inlineCode>{` suffix is now automatically appended to the names of connection pool metrics. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5466"
        }}>{`#5466`}</a>
        <ul parentName="li">
          <li parentName="ul">{`If you use a custom `}<a parentName="li" {...{
              "href": "type://MeterIdPrefix:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefix.html"
            }}>{`type://MeterIdPrefix`}</a>{` with `}<a parentName="li" {...{
              "href": "type://ConnectionPoolListener#metricCollecting(MeterRegistry,MeterIdPrefix):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ConnectionPoolListener.html#metricCollecting(io.micrometer.core.instrument.MeterRegistry,com.linecorp.armeria.common.metric.MeterIdPrefix)?full"
            }}>{`type://ConnectionPoolListener#metricCollecting(MeterRegistry,MeterIdPrefix)?full`}</a>{`,
this changes will affect your meter naming.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['codefromthecrypt', 'ikhoon', 'jrhee17', 'lishiki', 'minwoox', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      